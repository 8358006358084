import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import debounce from "debounce";
import { isMobile } from "@/lib/detectDevice";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_DEPARTMENTS } from "@/graphql/seller_chat/department";
import { GET_MESSAGES } from "@/graphql/seller_chat/message.js";
import { WEBSITE } from "@/config";

var MessageList = function MessageList() {
  return import("./components/SellerMessageList.vue");
};

var ChatMobile = function ChatMobile() {
  return import("./SellerChatMobile.vue");
};

export default {
  components: {
    MessageList: MessageList,
    ChatMobile: ChatMobile
  },
  created: function created() {
    document.title = "\u0E41\u0E0A\u0E17\u0E01\u0E31\u0E1A\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32 | ".concat(WEBSITE);
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "\u0E41\u0E0A\u0E17\u0E01\u0E31\u0E1A\u0E23\u0E49\u0E32\u0E19\u0E04\u0E49\u0E32"
    }]);
    this.poll();
  },
  data: function data() {
    var _this$$route$query = this.$route.query,
        _this$$route$query$d = _this$$route$query.d,
        d = _this$$route$query$d === void 0 ? 0 : _this$$route$query$d,
        id = _this$$route$query.id;
    return {
      d: Number(d),
      id: id,
      isMobile: isMobile(),
      pageUser: 1,
      searchUser: "",
      pageMessage: 1,
      listMessage: [],
      visible: !!Number(d),
      enabledScroll: true
    };
  },
  methods: {
    fetchMore: function fetchMore() {// this.pageMessage = this.pageMessage + 1;
    },
    getName: function getName(seller) {
      return seller.seller ? seller.seller.seller_name : seller.full_name;
    },
    debounceInput: debounce(function (e) {
      this.searchUser = e.target.value;
      this.pageUser = 1;
    }, 400),
    onRefetchMessage: function onRefetchMessage() {
      this.enabledScroll = true;
      this.$apollo.queries.seller_messages.refetch();
    },
    clickDept: function clickDept(item) {
      this.enabledScroll = true;
      this.d = item.seller.userId;
      this.pageMessage = 1;
      var path = "/chat/seller?d=".concat(this.d);
      this.visible = true;

      if (this.$route.fullPath !== path) {
        this.$router.push(path);
      }
    },
    poll: function poll() {
      var _this = this;

      setInterval(function () {
        _this.enabledScroll = false;

        _this.$apollo.queries.seller_messages.refetch();
      }, 5000);
      setInterval(function () {
        _this.$apollo.queries.sellerDepartments.refetch();
      }, 5500);
    }
  },
  watch: {
    "$route.query.d": function $routeQueryD(val) {
      this.d = Number(val) || 0;
    },
    "$route.query.id": function $routeQueryId(val) {
      this.id = val;
    }
  },
  apollo: {
    sellerDepartments: {
      query: GET_DEPARTMENTS,
      variables: function variables() {
        return {
          id: this.id
        };
      }
    },
    seller_messages: {
      query: GET_MESSAGES,
      skip: function skip() {
        return !this.d && !this.id;
      },
      variables: function variables() {
        return {
          page: this.pageMessage,
          sellerId: this.d,
          id: this.id
        };
      },
      result: function result(_ref) {
        var loading = _ref.loading,
            data = _ref.data;

        if (!loading && data) {
          var seller_messages = data.seller_messages;

          if (this.pageMessage != 1) {
            this.listMessage = seller_messages.records.concat(this.listMessage);
          } else {
            this.listMessage = seller_messages.records;
            var container = this.$el.querySelector("#message_wrapper");

            if (container && this.enabledScroll) {
              this.$nextTick(function () {
                setTimeout(function () {
                  container.scrollTop = container.scrollHeight;
                }, 500);
              });
            }
          }
        }
      }
    }
  }
};